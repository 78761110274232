(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/coupon-bet-buttons/assets/javascripts/coupon-bet-buttons.js') >= 0) return;  svs.modules.push('/components/tipsen/coupon-bet-buttons/assets/javascripts/coupon-bet-buttons.js');
"use strict";

const {
  useSelector,
  useDispatch,
  shallowEqual
} = ReactRedux;
const {
  selectEventSelections,
  selectEventUserSelections,
  selectSystemType,
  selectMSign,
  selectEventUSign,
  selectRSystem,
  selectUSystem
} = svs.components.tipsen.engine.selectors.couponSelectors;
const {
  toggleAlternative,
  toggleMSign
} = svs.components.tipsen.engine.actions;
const {
  ReactButtonBet
} = svs.ui;
const {
  SYSTEM_RSYS,
  SYSTEM_USYS
} = svs.components.tipsen.engine.constants.systems;
const {
  useBranding
} = svs.components.tipsen.hooks;
const {
  selectIsKeyLoading
} = svs.components.tipsen.loadingState.selectors;
const {
  LoadingKeys
} = svs.components.tipsen.loadingState.constants;
const CouponBetButtons = _ref => {
  let {
    couponId,
    board,
    event,
    outcomeLabels,
    outcomes,
    hasDisabledBets
  } = _ref;
  const selections = useSelector(state => selectEventSelections(state, couponId, board, event)) || [];
  const userSelections = useSelector(state => selectEventUserSelections(state, couponId, board, event)) || [];
  const uSign = useSelector(state => selectEventUSign(state, couponId, board, event));
  const rSystem = useSelector(state => selectRSystem(state, couponId));
  const uSystem = useSelector(state => selectUSystem(state, couponId));
  const dispatch = useDispatch();
  const systemType = useSelector(state => selectSystemType(state, couponId));
  const hasMSigns = [SYSTEM_RSYS, SYSTEM_USYS].includes(systemType) && (rSystem || uSystem);
  const mSign = useSelector(state => selectMSign(state, couponId, board, event), shallowEqual);
  const [productBranding] = useBranding();
  const isLoadingPix = useSelector(state => selectIsKeyLoading(state, LoadingKeys.PIX));
  return React.createElement(React.Fragment, null, hasMSigns && React.createElement("div", {
    className: "pg_bet_buttons__m_sign "
  }, React.createElement(ReactButtonBet, {
    "aria-checked": mSign.value,
    branding: productBranding,
    className: "coupon-bet-buttons-button qa-coupon-bet-button",
    "data-testid": "".concat(board, "_").concat(event, "_M"),
    disabled: mSign.disabled || hasDisabledBets || isLoadingPix,
    label: "MG",
    onClick: e => {
      e.preventDefault();
      dispatch(toggleMSign({
        board,
        couponId,
        event
      }));
    },
    role: "checkbox",
    selected: mSign.value,
    type: "button"
  })), React.createElement("div", {
    className: "coupon-bet-buttons"
  }, selections.map((selection, index) => {
    const userSelected = userSelections[index];
    const label = outcomeLabels[index] ? outcomeLabels[index].ariaLabel || outcomeLabels[index].text : undefined;
    const classNames = ['coupon-bet-buttons-button', 'qa-coupon-bet-button'];
    if (selection && userSelected) {
      classNames.push('coupon-bet-buttons-user-selected');
    } else {
      classNames.push('coupon-bet-buttons-pix-selected');
    }
    const key = "".concat(board, "_").concat(event, "_").concat(index);
    return React.createElement(ReactButtonBet, {
      "aria-checked": selection,
      "aria-label": label,
      branding: productBranding,
      className: classNames.join(' '),
      "data-testid": key,
      disabled: hasDisabledBets || isLoadingPix,
      key: key,
      label: outcomes[index] || label,
      onClick: clickEvent => {
        clickEvent.preventDefault();
        dispatch(toggleAlternative({
          boardIndex: board,
          couponId,
          event,
          outcome: index,
          systemType
        }));
      },
      role: "checkbox",
      selected: selection,
      system: uSign === index && systemType === SYSTEM_USYS ? 'U' : undefined
    });
  })));
};
setGlobal('svs.components.tipsen.couponBetButtons.CouponBetButtons', CouponBetButtons);

 })(window);